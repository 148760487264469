import { ENDPOINTS, PagingOptions } from "@api";
import { ListLeaves, PaperHeader } from "@components";
import { useFetch } from "@hooks";
import {
  Action,
  ComplexHeader,
  ExportData,
  Table,
  TableVerticalConfigs,
} from "@shared";
import { BranchSelection } from "@widgets";
import React, { useEffect, useState } from "react";
import { RiSearchLine } from "react-icons/ri";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

type Search = { branchId: string; year: string; month: string | null };

const LeaveSummaryReport = () => {
  const MySwal = withReactContent(Swal);
  const [queryStrings] = useSearchParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [headers, setheaders] = useState<ComplexHeader[]>([]);
  const [searchParams, setSearchParams] = useState<Search>({
    branchId: "",
    year: new Date().getFullYear().toString(),
    month: null,
  });

  const fetchData = useFetch<{}[]>(
    {
      endPoint: ENDPOINTS.leaveSummaryReport,
      queryStrings: queryStrings,
    },
    []
  );

  useEffect(() => {
    if (!searchParams.branchId) {
      navigate({ search: `` });
    }

    if (fetchData?.data?.length > 0) {
      var x: ComplexHeader[] = [];

      Object.keys(fetchData?.data[0]).forEach((d) => {
        x.push({ key: d, title: d.replaceAll("_", " ") });
      });

      setheaders(x);
    }
  }, [queryStrings, fetchData.isFetching]);

  const actions: Action[] = [
    {
      key: "",
      actionType: "badge",
      click: (row: any) => {
        MySwal.fire({
          showConfirmButton: false,
          allowOutsideClick: false,
          showCloseButton: true,
          width: 900,
          html: (
            <ListLeaves
              employeeId={row?.id}
              readOnlyMode={true}
              query={queryStrings}
            />
          ),
        });
      },
      title: "Details",
      color: "info",
    },
  ];

  const onExport: ExportData = {
    data: () => Promise.resolve(fetchData?.data),
    fileName: `Leaves Summary Report - ${new Date().toLocaleDateString()}`,
  };

  const onPrint = (element: HTMLElement) => {
    var subElement = element.querySelectorAll(":scope > div")[0];
    subElement.classList.remove("d-none");

    var subElement02 = element.querySelectorAll(":scope > div")[1];
    subElement02.classList.add("d-none");

    let table = element.getElementsByTagName("table")[0];
    table.classList.remove("table-striped");
  };

  const handleSearch = async () => {
    // if (!searchParams.branchId || !searchParams.year) return;

    setIsLoading(true);

    queryStrings.set("branchId", searchParams.branchId);
    queryStrings.set("year", searchParams.year);

    if (searchParams.month) {
      queryStrings.set(
        "month",
        (new Date(searchParams.month).getMonth() + 1).toString()
      );
    } else {
      queryStrings.delete("month");
    }

    navigate({ search: `?${queryStrings.toString()}` });

    setIsLoading(false);
  };

  return (
    <div className="content container-fluid">
      <div className="row align-items-center">
        <div className="d-flex justify-content-between mb-1">
          <div className="col-4">
            <h3 className="page-title">Leave Summary Report</h3>
          </div>
        </div>
      </div>

      <div className="row mt-3" id="reportcontents">
        <PaperHeader title="Leave Balances Report" />
        <div className="d-flex justify-content-between mb-3">
          <div className="d-flex justify-content-start gap-2 col-6 mt-1">
            <div className="col">
              <div className="form-group mb-0 row">
                <label className="col-form-label col-auto">Branch</label>
                <div className="col">
                  <BranchSelection
                    className="form-control-sm row"
                    isSelectedOption={+searchParams?.branchId}
                    returnValue={(val: number) =>
                      setSearchParams((prev) => ({
                        ...prev,
                        branchId: val?.toString(),
                      }))
                    }
                  />
                </div>
              </div>
            </div>
            <div className="col">
              <div className="form-group mb-0 row">
                <label className="col-form-label col-auto">Year</label>
                <div className="col">
                  <input
                    type="number"
                    min="2022"
                    max="2099"
                    step="1"
                    defaultValue={searchParams.year}
                    className="form-control form-control-sm row"
                    onChange={(e) =>
                      setSearchParams((prev) => ({
                        ...prev,
                        year: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
            </div>
            <div className="col">
              <div className="form-group mb-0 row">
                <label className="col-form-label col-auto">Month</label>
                <div className="col">
                  <input
                    type="month"
                    className="form-control form-control-sm row"
                    onChange={(e) =>
                      setSearchParams((prev) => ({
                        ...prev,
                        month: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-auto float-end ms-auto">
            <button
              disabled={isLoading}
              className="btn btn-sm btn-outline-info rounded-circle m-1 float-right"
              onClick={handleSearch}
            >
              <RiSearchLine />
            </button>
            {headers.length > 0 && (
              <TableVerticalConfigs
                exportAsXSLS={onExport}
                onPrint={{
                  ref: "reportcontents",
                  prePrint: onPrint,
                }}
              />
            )}
          </div>
        </div>
        <div className="row">
          {headers.length > 0 && (
            <Table
              class="table table-striped table-hover mb-0"
              data={fetchData?.data ?? []}
              headers={headers}
              actions={actions}
              isFetchingPage={fetchData?.isFetching}
              showCounter
              // onPageChange={handlePageChange}
              paginationClass="row mt-3"
              // showTotals
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default LeaveSummaryReport;
